#line-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;   
}

.walk-app-top-half {
    max-width: 600pt;
    margin-right: auto;
    margin-left: auto;
    border-radius: 3pt;
    /* outline: rgb(225, 225, 225) 1px solid; */
}


.thought-walk-step {
background-color: white;
height: fit-content;
flex: 1;
padding: 6pt;
margin: 4pt 8pt;
border-radius: var(--thought-border-radius);
border: solid 2px rgb(223, 223, 223);
border-color: rgb(223, 223, 223);
cursor: pointer;
}
.thought-walk-step.reply {
    /* margin-top: -.9ex; */
}

.gray {
opacity: .5;

}
.thought-walk-step.smaller {
opacity: .4;
}
.notLast {
opacity: .7
}

.thought-walk-step.stepthrough-preview {
    /* flex: 1; */
    /* border:none; */
    /* opacity: .5; */
    margin-right: 0 !important; 
    /* box-shadow: 0 0 8px gainsboro */
    /* overide other setting */
}

.thought-walk-step.small {
min-width: 200pt;
/* width: 36vw; */
height: 7em;
flex: 0;
overflow: hidden;
box-shadow: none;
border-style: none;
overflow-y: scroll;
margin: 1em .5em;
/* background: rgb(245, 245, 245) */
box-shadow: 0 0 8px 6px rgb(236, 236, 236);



}

.thought-walk-step.small-next-step {
    /* min-width: 200pt;
    width: 36vw;
    max-width: 400pt; */
    margin-right: 0;
margin-left: 0;
/* margin-bottom: 2ex; */

/*     
    min-width: none;
    max-width: none;
    width: 90%; */

    }
.thought-walk-step.copy {
opacity: .5;
}
/* different styling for selectd past step */
.thought-walk-step.selected.past-step:not(.isMobile) {
/* background-color: rgb(245, 245, 245); */
/* outline:6px solid rgb(238, 238, 243); */
border-color: rgb(220, 220, 220);

}
/* ... than for selected next step */
.thought-walk-step.small.selected:not(.isMobile) {
    /* border-style: solid; */

    /* outline:6px solid rgb(239, 239, 239); */
}

.input-field.thought-walk-step {
    cursor: text;
    height: fit-content !important;
    background-color: white;
    margin: 8pt;
    padding: 8pt;
    width: inherit;
    max-width: none;
    /* margin-top: -.9ex; */
    /* border: transparent; */
    box-shadow: 0 0 8px 6px rgb(236, 236, 236);


}
.input-field:not(.empty) {

}
.thought-walk-step.stale:not(.small) {
    /* margin-right: 4vw; */
    /* margin-left: 4vw; */
    background-color: rgb(246, 246, 246);
    

  
}
.thought-walk-step.fresh {
    /* margin-left: 6vw; */
     /* border-color: white; */
     /* font-size: 1em; */

}
.thought-walk-step.stale:not(.small).conversation-mode {
    margin-right: 8vw;
    /* margin-left: 4vw; */
    background-color: rgb(246, 246, 246);
    

  
}
.thought-walk-step.fresh.conversation-mode {
    margin-left: 8vw;
     /* border-color: white; */
     /* font-size: 1em; */

}

.centered-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content
}
#thought-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    overflow-x: auto;
    /* margin-top: -2em; */
    margin-bottom: -1em;
}
#thought-row.single-next-step-row{
    justify-content: center;


}
.section-title {
    padding: 0 1em;
    opacity: .3;
}

.reply-button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 15px;

}

.reply-button {
    justify-content: center;
    width: 70px;
    background-color: white;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.reply-button:hover {
    background-color: #f5f3f3;
}
.walk-header {
    /* position: absolute; */
    color: gray;
    padding: .5ex;
    padding-right: 2ex;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    z-index: 10;
    border-bottom: 1px solid rgba(192, 192, 192, 0.546);
    /* box-shadow: 0 0 3px solid gainsboro; */
}

.begin-new-walk-button {
    opacity: .6;
    display: flex;
    align-items: center;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5ex;
    border-radius: 6pt;
    /* background-color: rgb(235, 241, 241); */
    width: fit-content;
    margin-left: auto; margin-right: auto;
    cursor: pointer;
}

.begin-new-walk-button:hover{
    opacity: 1;
    /* background-color: rgb(246,246,246); */
}

.close-walk{
    color: rgb(198, 198, 198);
    /* background-color: rgb(244, 244, 244); */
    border-radius: var(--thought-border-radius);
    padding: 8pt;
    margin: 8pt;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    /* font-style: italic; */ 
}
.slate-placeholder {
    position: relative; /* Added */
  }
  
  .slate-placeholder:focus::before,
  .slate-placeholder:valid::before {
    display: none;
  }
  
  .slate-placeholder.is-empty::before {
    content: "Express a reply...";
    color: #999;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0pt;
  }
  
  .slate-placeholder:not(.is-empty)::before {
    display: none;
  }
  