.collapse-walk-button-container {
    /* color:blue; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(191, 191, 191);
    /* border-top: 2pt dashed gainsboro; */
    margin-top: 1em;
    min-width: 30ex;
    padding: 4pt;
    cursor: pointer;
}
/* .collapse-icon {
    color: rgb(190, 175, 175);
} */
.collapse-walk-button-container:hover{
    /* border-color: gray; */
    /* border-top-style: solid; */
    color: gray;
    font-weight: bold;
    /* border: 1pt solid gainsboro; */

}

/* .collapse-walk-button-container:hover > .collapse-icon{
    color: rgb(85, 34, 34);

} */



