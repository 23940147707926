.survey-popup-container{
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(247, 247, 247, 0.882);
    
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;align-items: center;
    font-family: Optima;
    overflow-y: auto;
}
.survey-window {
    opacity: 1;
   padding: 5vh;
   margin: 10vh 5vh;
   text-align: center;
   height: fit-content;
    background-color: white;
    box-shadow: 0 0 10pt gainsboro;
    border-radius: 5pt;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    max-height: 90vh;

}
.fake-link {
    color: rgb(51, 0, 123);
    text-decoration: underline;
    font-size: em;
    cursor: pointer;
    font-weight: bold;

}
.survey-oneliner {
    font-size: 1.5em;
}
