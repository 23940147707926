.thought-contenteditable {
    width: 100%;
    /* background-color: blue; */
}

.thought-contenteditable:empty::before {
    content: attr(data-placeholder);
    color: #a9a9a9; 
    pointer-events: none; 
  }
