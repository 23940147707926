[contentEditable]:focus {
    outline: none;
}


.word-processor-container {
    background-color: #ffffff;
    max-width: 800px;
    width: 100%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 30px 40px;
    font-family: 'Arial', sans-serif;
}

.editable-title {
    font-size: 2em;
    text-align: center;
    margin-bottom: 20px;
    outline: none;
    cursor: text;
}
  
.word-processor {
    padding: 20px;
    padding-left: 25px;
    padding-top: 0px;
    /* min-height: 80vh; */
    max-width: 700px;
    outline: none;
}
  
.thoughts {
    margin-top: 20px;
    max-width: 800px;
    margin: 20px auto 0;
    margin-left: 25px;
}
  
.writer-thought {
    margin-bottom: 10px;
    position: relative;
}
  

.suggestion:hover {
    color: orange;
}
.finish-draft-button {
    margin: 1ex;
}
.finish-draft-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
}


.suggestion {
    border-bottom: 1px solid grey;
    padding: 8px;
}

.suggestion-text {
    font-weight: bold;
}

.suggestion-author {
    font-style: italic;
}

.dot-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
}
  
.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: flex;             
    justify-content: center;   
    align-items: center;       
    font-size: 8px;        
    padding: 3px;    
}

  
.suggestions-popout {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    width: 250px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
  
.thought-content {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

  
.citations-container {
    margin-left: 5px;
    white-space: nowrap;
}

.highlighted {
    color: purple;
}
 
.citation-number {
    position: relative;
    vertical-align: super;
    font-size: 0.7em;
    cursor: pointer;
    margin-left: 3px; 
    user-select: none; 
}


.tooltip {
    display: none;
    position: absolute;
    top: 15px; /* Adjust this value as necessary */
    left: 50%;
    transform: translateX(-95%);
    background-color: #f9f9f9;
    color: #333;
    padding: 5px 10px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    font-size: 0.8em;
    z-index: 15;
    width: 25vh;
}

.citation-number:hover .tooltip {
    display: block;
}

.citations-list {
    margin-top: 20px;
}

.cite {
    cursor: pointer;
    font-size: 80%;
    padding-bottom: 4px;
}

.writing-page {
    background-color: #eef2f7;  
    height: 100vh;
    display: flex;
    justify-content: center; 
    /* padding: 50px 100px;   */
  }
  


