.feed-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}



.feed-item-container.breadcrumb {
  /* outline: none; */
  margin-bottom: 0pt;
  margin-top: 5pt;
  color: gray;
}.feed-item-container.suggested-thought {
  /* outline: none; */
  margin-bottom: 5pt;
  margin-top: 5pt;
}
.feed-item-container.update-thought {
  margin-bottom: 0pt;
  margin-top: 5pt;
  color: gray;
}

.feed-cont-cont-cont.beingDeleted {
  opacity: 0.3;
}

.feed-item-container.suggested {
  background-color: transparent;
  box-shadow: 0 0 0px 2px rgb(220, 220, 220);
}


/* when related are shown, change it */
.feed-item-container.showingRelated {
  /* background-color: transparent; */
  /* border-left: 3px rgb(245, 245, 245) solid; */

  /* outline: 4px rgb(245, 245, 245) solid; */
}


.feed-item-container {
  min-height: 1em;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding: var(--thought-padding);
  border: var(--thought-outline);
  border-radius: var(--thought-border-radius);
  -webkit-border-radius: var(--thought-border-radius);
  /* 4px rgb(245, 245, 245) solid; */
  cursor: pointer;
  margin: var(--thought-margin);
  /* width: 100%; */

  /* color: maroon; */
}
.feed-item-container:hover:not(.principal-thought){
  outline-color: rgb(96, 96, 96);
  /* box-shadow: 0 0 1px 1px rgb(131, 131, 131); */
}
.in-thought-title {
  /* text-decoration: underline; */
  /* font-weight: bold; */
  font-style: italic;
}

.feed-item-container.visited:not(.principal-thought) {
  opacity: .6;
}

.feed-item-container.principal-thought {
  margin-bottom: 0;
  cursor: default;
  background-color: transparent;
  cursor: default;
  /* z-index: 10; */
  /* outline: none; */
  /* font-size: 1.1em; */
}
.chat-progress-tag {
  /* margin: 0 1em; */
  font-style: italic;
  opacity: 0.5;
  cursor: pointer;
}
.dismiss-button-in-thought-header {
  margin: 0 1em;
  background-color: transparent;
  border: none;
}
.dismiss-button-in-thought-header:hover {
  text-decoration: underline;
}
.dismiss-button-in-thought-header:active {
  color: black;
}

.section-label {
  color: rgb(165, 165, 165);
  margin: 0.2em;
  margin-bottom: 0;
  font-size: 1.2em;
  cursor: pointer;
}

.feed-item-header {
  display: flex;
  flex-direction: row;
  margin:  0 0;
  /* align-self: flex-end; */
  justify-content: space-between;
}
.feed-item.bubbley {
  /* box-shadow: var(--super-bubble); */
}
/* .feed-item:active {
  border-radius: 0.4em;
  background-color: rgb(239, 237, 255);
} */
.feed-item-buttons {
  opacity: 0.5;
  /* align-items: center; */
  min-width: 3em;
}
.feed-item-button.open:hover {
  padding: 0;
}
.feed-item-button.delete {
  margin-left: 1em;
}
.feed-item-button.open:hover {
  text-decoration: underline;
}
.feed-item-button {
  display: flex;
  align-items: center;
}

.feed-item-button.pin {
  width: fit-content;
  border-radius: 0.5em;
  transition: background-color 0.1s, padding 0.2s, margin 0.2s, border-radius 0.2s;
}
.feed-item-button.pin:active {
  color: var(--active-purple);
  outline: none;
}
.feed-item-button.invisible {
  visibility: hidden;
}
.author-tag {
  /* font-weight: bold; */
}
.author-tag.emailable {
  color: blue;
  font-weight: bold;
}

.clickable-author-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.link-button:active {
  background-color: black;
  font-weight: bold;
  color: #ffffff;
}

.reply-button-container {
  align-items: center;
  justify-content: center;
}

.button-option-1 {
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: Neucha, sans-serif;
  font-size: 1.2rem;
  line-height: 23px;
  margin-right: -160px;
  outline: none;
  padding: 0.75rem;
  text-decoration: none;
  transition: all 100ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-option-1:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}

.button-option-1:focus {
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 4px -6px;
}

.button-option-2 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 0.375em;
  box-shadow: none;
  box-sizing: border-box;
  color: #363636;
  cursor: pointer;
  display: inline-flex;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  height: 2.5em;
  justify-content: center;
  line-height: 1.5;
  padding: calc(0.5em - 1px) 1em;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-option-2:active {
  border-color: #4a4a4a;
  outline: 0;
}

.button-option-2:focus {
  border-color: #485fc7;
  outline: 0;
}

.button-option-2:hover {
  border-color: #b5b5b5;
}

.button-option-2:focus:not(:active) {
  box-shadow: rgba(72, 95, 199, 0.25) 0 0 0 0.125em;
}

.button-option-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.4em;
  margin: 0.3em;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  border-radius: 6px;
  border: none;
  width: 3.6em;
  font-size: 1em;
  color: rgb(43, 43, 43);
  /* background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%); */
  background-color: #d6ddeb;
  background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-option-3:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

.button-option-3:hover {
  cursor: pointer;
}
.button-option-3.replied-already {
  /* background-color: transparent;
   color: gainsboro; */
  opacity: 0.3;
}
.reply-count {
  padding: 0.2em;
  border-radius: 0.2em;
  /* margin: .1em; */
  opacity: 0.5;
  cursor: pointer;
}
.connect-button {
  cursor: pointer;
}
.connect-button.alreadyConnected {
  opacity: 0.3;
}

.reply-count:active {
  border-radius: 0.5em;
  color: black;
  background-color: gainsboro;
}

.inthought-text {
  opacity: 0.6;
  /* font-size:  .9em; */
  cursor: default;
}
.thought-footer {
  font-size: 1em;
}

.expanded-divider {
  height: 2px;
  background-color: rgb(239, 239, 239);
  margin: 0.5em 0em;
}

.delete-button-padder {
  padding: 0 1em;
  font-size: 1.1em;
  margin-top: -0.2em;
  margin-bottom: -0em;
  font-weight: bold;
}
