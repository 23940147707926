.landing-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-size: 1.1em;
  text-align: left;
  font-family: Optima;
  position: relative;
  
}
.landing-page::before {
  content: "";
  background-image: url('../../assets/roots.png') ;
  opacity: 0.2; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  background-size: 100% auto;
  height: 100vh;
  z-index: -1; 
  transition: opacity .3s;
}
.landing-page:active.landing-page::before {
  opacity: .9; /* Adjust the opacity value (0.0 to 1.0) as needed */
  z-index: 2;
}

.landing-page-text {
  max-width: 32em;
  padding: 2em;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.top-part {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.big-statement {
  padding: 1em;
  font-size: xx-large;
}

.small-statement {
  color: black;
  text-decoration: none;
}

.enter-plexus {
  background-color: rgb(244, 244, 244);
  /* color: rgb(176, 0, 44); */
  width: fit-content;
  border-radius: var(--thought-border-radius);
  padding: .5em;
  margin: 1em;
  /* transition: background-color 3s ease-in, color 0s ease-in, padding 1s ease-in, outline .5s ease-in, border-radius 1s ease-in; */
  cursor: pointer;
  display: flex;
  align-items: center;
}

.enter-plexus:hover {
  text-decoration: underline;
  background-color: rgb(236, 239, 246);

}

.enter-plexus:active {
  /* /* background: black; */
  color: white;
  font-family: cursive;
  font-size: 24pt;
  font-weight: bold;
  background: rgb(51, 0, 255);
  box-shadow: 0 0 0 1000pt rgb(51, 0, 255);
  z-index: 10;
  text-decoration: none;

}

.log-on-text {
  /* color: #fff;  */
  cursor: pointer;
  text-decoration: underline;
}

.log-on-text:hover {
  color: black;
}

.link-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.link-row>* {
  padding: 1ex;
  font-style: italic;
  color: rgb(155, 155, 155);
  /* font-size: small; */
  /* display: block; */
}

.tech-crunch-button {
  background: #22c022;
  margin-top: 30px;
  padding: 10px 0px;
  font-size: 24px;
  border-radius: 8px;
  cursor: pointer;
  width: fit-content;
  align-self: center;
  font-weight: bold;
}

.tech-crunch-botton:hover {
  background: #3bb43b
}

.tc-text {
  text-decoration: none;
  padding: 10px 40px;
}

.primary-enter-button {
  padding: .5em;
  margin: .2em;
  /* margin-top: 1em; */
  font-size: 1em;
  /* background-color: rgb(255, 196, 196); */
  box-shadow: 0 0 20px 2px gray;
  cursor: pointer;
  width: 150pt;
  border-width: 2pt;
  max-width: 80vw;
  vertical-align: center;
  font-weight: bold;
  background-color: transparent;



}
.primary-enter-button:active{
  z-index: 3;
}

.secondary-enter-button:active{
  z-index: 3;
}
.secondary-enter-button {
  padding: .5em;
  margin: .2em;
  font-size: 1em;
  width: 150pt;
  max-width: 80vw;
  background-color: none;
  opacity: .3;
  border-width: 2pt;
  background-color: transparent;


  /* outline-color: gainsboro; */
  /* color: gainsboro; */

  /* background-color: rgb(255, 196, 196); */
  /* box-shadow: 0 0 12px gray; */
  cursor: pointer;
}

