.walk-statistics-container {
    color: blue
}

.admin-stats-section-inner{
    cursor: pointer;
}
.admin-stats-section-container {
    margin: 1em 0;
}
