#single-next-step-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-top: -1ex; */
}
.caret{
    cursor: pointer;
    padding: 2pt;
    /* margin: 1ex; */
}
.caret:hover {
background-color: rgb(248, 248, 248);
border-radius: 5pt;
}
.caret.disabled {
    opacity: 0.2;
    cursor: default;
}
