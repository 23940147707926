.past-steps-container {
    /* color: blue */}

.centered-ellipses {

    text-align: center;
    color: rgb(161, 161, 161);
    font-size: 2ex;
    
  
    /* border-top: 1px gray solid; */
}
.centered-walk-break {
    text-align: center;
    margin-top: .5ex;
    margin-bottom: .5ex;
    align-self: center;
    min-width: 10em;
    border-top: 2px rgb(235, 235, 235) solid;
}
.collapsed-step {
    color: rgb(209, 209, 209);
    cursor: pointer;
}

/* .collapsed-step:hover {
    color: black;
} */

.past-walk-step {
    display: flex; flex-direction: column;
}
