.editor-container {
  width: 100%;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  margin: 0.5vh 0;
  flex: 1;
}
.editor-container > div {
  height: fit-content;
}
.editor-container > div > div + div {
  margin-top: 0.8em;
}

.buttons-in-editor {
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
}
.buttons-in-editor > * {
  margin-left: 0.2em;
  margin-right: 0.2em;
  padding: 0.3em;
}
