.unified-walk-container {
    color:blue;
}
#unified-walk-container {
    /* color: blue; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; */
    overflow-y: auto;
    overflow-x: hidden;
    /* background-color: rgb(250, 250, 250); */


}
