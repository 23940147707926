@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
  }
  
.fadeout {
    animation-name: fadeout;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

.walk-header-icon-container {
    display: flex;
    align-items: flex-start;
    opacity: .6;
}

.walk-header-icon-text {
    margin-right: 10px;
}  
